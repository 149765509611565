'use client'

import React, {useState} from "react";
import AppButton from "@/app/components/atomic/molecules/AppButton";
import {getWCTRedirectUrl, isPartnerLink, trackPartnerLinkClick} from "@/app/lib/wecantrack/clickout";
import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'));

interface IButtonBlockProps {
    target: string;
    label: string;
    size?: any;
    theme?: any;
}

export default function PartnerLinkButton({target, size, theme, label}: IButtonBlockProps) {
    const [isGeneratingLink, setIsGeneratingLink] = useState(false);

    let buttonLink = target;

    const onButtonClick = async (event: any) => {
        event.preventDefault();

        if (isPartnerLink(buttonLink)) {
            trackPartnerLinkClick(buttonLink);

            setIsGeneratingLink(true);
            const redirectUrl = await getWCTRedirectUrl(buttonLink)
            setIsGeneratingLink(false);

            if (redirectUrl) {
                window.open(redirectUrl, '_blank');
            } else {
                window.open(buttonLink, '_blank');
            }
        } else {
            window.open(buttonLink, '_blank');
        }
    }

    return (
        <AppButton
            isLink
            href={buttonLink}
            size={size ?? "block"}
            onClick={(e) => onButtonClick(e)}
            theme={theme ?? 'orangePrimary'}
        >
            {isGeneratingLink &&
                <AppIcon iconClassName="fa-spinner-third" className="animate-spin mr-2" />
            }
            {!isGeneratingLink &&
                <>
                    {label}
                    {theme === 'whitePrimaryArrow' && (
                        <AppIcon
                            iconClassName={'fa-arrow-right'}
                            type={'regular'}
                            size={'lg'}
                        />
                    )}
                </>
            }
        </AppButton>
    )
}
