'use client'
import {FormEvent, useState} from "react";
import {ENDPOINTS} from "@/app/endpoints/wpEndpoints";
import dynamic from "next/dynamic";

const AppInput = dynamic(() => import('@/app/components/atomic/Input/AppInput'));
interface FormData {
    name: string;
    email: string;
    message: string;
}

export function PageCommentForm({wpPageId}: { wpPageId: string}) {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        message: '',
    });

    const [emailError, setEmailError] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const handleChange = (inputReturn: any) => {
        setFormData({...formData, [inputReturn.fieldName]: inputReturn.value});
    };

    const validateEmail = (email: string): boolean => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (formData.email && !validateEmail(formData.email)) {
            setEmailError('Votre email est invalide');
            return;
        } else {
            setEmailError('');
            await postNewComment();
        }
    };

    const postNewComment = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch(ENDPOINTS.POST_NEW_COMMENT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any necessary authentication headers here
                },
                body: JSON.stringify({
                    pageId: wpPageId,
                    name: formData.name,
                    email: formData.email,
                    message: formData.message,
                }),
            });

            setIsSubmitting(false);
            if (response.ok) {
                setIsSubmitted(true);
            } else {
                setHasError(true);
            }
        } catch (error) {
            setIsSubmitting(false);
            setHasError(true);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-8">
            <div className="flex flex-col gap-8">
                <label
                    htmlFor="message"
                    className="flex flex-col justify-center items-start text-3xl text-blue-700"
                >
                    <span className="font-bold">
                        Posez une question
                    </span>
                    <span>
                        <span className="border-b-4 border-blue-600">
                            un&nbsp;
                        </span>
                        expert vous répondra
                    </span>
                </label>
                <textarea
                    className="h-40 p-4 border border-blue-300 rounded overflow-hidden w-full transition-all duration-500
                    hover:border-blue-500 focus-within:border-blue-500"
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={(e) => handleChange({ value: e.target.value, fieldName: 'message' })}
                    required
                />
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
                <div className="flex-1 flex flex-col">
                    <label className="text-blue-700 font-semibold w-full" htmlFor="name">Votre nom</label>
                    <AppInput
                        fieldName="name"
                        onChange={(inputReturn) => handleChange(inputReturn)}
                        value={formData.name}
                        required
                    />
                </div>
                <div className="flex-1 flex flex-col">
                    <label className="text-blue-700 font-semibold w-full" htmlFor="email">Votre email <small>(facultatif)</small></label>
                    <AppInput
                        fieldName="email"
                        onChange={(inputReturn) => handleChange(inputReturn)}
                        value={formData.email}
                    />
                    {emailError && <p className="text-red-500">{emailError}</p>}
                </div>
            </div>
            {isSubmitted &&
                <div className="text-green-500 font-semibold mx-auto">
                    Votre commentaire a été envoyé avec succès !
                </div>
            }
            {hasError &&
                <div className="text-red-500 font-semibold mx-auto">
                    Une erreur est survenue, veuillez réessayer plus tard.
                </div>
            }
            <button
                className="flex w-52 mx-auto justify-center items-center gap-2 px-5 py-4 rounded-lg md:rounded font-bold text-white text-sm bg-orange-700
                            transition-all duration-300 whitespace-nowrap
                            hover:shadow-outline hover:shadow-orange-500"
                type="submit"
            >
                Laisser un commentaire
            </button>
        </form>
    );
}
