'use client'
import Select, {components as selectComponents} from "react-select";
import {useEffect, useState} from "react";
import {IAppliedFilter, ISelectFilterConfig} from "@/app/models/auctor";
import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'));

function OptionWithIcon({data, ...props}: any) {
    return (
        <selectComponents.Option className='text-blue-900 hover:bg-blue-100 transition-all cursor-pointer' {...props}>
            {data.icon !== null && data.icon !== '' &&
                <AppIcon className={"mr-2"} iconClassName={data.icon} />
            }
            {data.label}
        </selectComponents.Option>
    );
}

function DropdownIndicator({state, ...props}: any) {
    const isOpenClass = props.selectProps.menuIsOpen ? 'rotate-180' : ''
    return (
        <selectComponents.DropdownIndicator {...props}>
            <AppIcon iconClassName={`fas fa-caret-down text-blue-900 transition-all origin-center cursor-pointer ${isOpenClass}`} />
        </selectComponents.DropdownIndicator>
    )
}

function SingleValue({data, ...props}: any) {
    return (
        <selectComponents.SingleValue className='text-blue-900 cursor-pointer' {...props}>
            {data.icon !== null && data.icon !== '' &&
                <AppIcon className={"mr-2"} iconClassName={data.icon} />
            }
            {data.label}
        </selectComponents.SingleValue>
    )
}

export default function FilterSelect({filterConfig, appliedFilter, handleChange}: {
    filterConfig: ISelectFilterConfig,
    appliedFilter: IAppliedFilter,
    handleChange: any
}) {
    const id = Date.now().toString();
    const [isMounted, setIsMounted] = useState(false);

    // Must be deleted once
    // https://github.com/JedWatson/react-select/issues/5459 is fixed.
    useEffect(() => setIsMounted(true), []);

    const onChange = (newValue: any) => {
        handleChange(appliedFilter, newValue);
    }

    return isMounted ? (
        <>
            <Select
                id={id}
                className="w-full text-blue-900 cursor-pointer"
                classNames={{
                    control: (state) => `focus-within:border-blue-500 hover:border-blue-500 shadow-none h-12
                    border-blue-300 transition-all duration-500`,
                    indicatorSeparator: (state) => 'hidden',
                    option: (state) => state.isSelected ? 'bg-blue-200 font-semibold' : 'bg-white',
                    multiValue: (state) => 'bg-blue-200 font-semibold rounded'
                }}
                placeholder={'Sélectionnez...'}
                options={filterConfig.options}
                defaultValue={appliedFilter.value}
                isMulti={filterConfig.is_multiple}
                blurInputOnSelect={true}
                isSearchable={false}
                isClearable={false}
                components={{
                    Option: OptionWithIcon,
                    DropdownIndicator,
                    SingleValue,
                }}
                noOptionsMessage={() => 'Aucune option disponible'}
                onChange={onChange}
                menuPortalTarget={document.body}
                styles={{
                    menuPortal: base => ({...base, zIndex: 20}),
                }}
            />
        </>
    ) :  <HTMLSelectSkeleton options={filterConfig.options} defaultValue={appliedFilter.value} />
}

export function SelectFilterSkeleton() {
    return (
        <>
            <div className="w-full">
                <div className="w-full h-[42px] bg-blue-200 rounded animate-pulse" />
            </div>
        </>
    )
}

export function HTMLSelectSkeleton({options, defaultValue}: {options?: any[], defaultValue: string}) {
    return (
        <select
            className="w-full h-12 px-3 py-2 text-blue-900 bg-white border border-blue-300 rounded-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            defaultValue={defaultValue}
        >
            {options?.map((option: any, index: number) => (
                <option key={`${option.value}-${index}`} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    )
}
