'use client'
import {IWPComment, IWPCommentsPayload} from "@/app/models/wp";
import {useState} from "react";
import {getWPPageComments} from "@/app/endpoints/wpEndpoints";
import AppIcon from "@/app/components/atomic/AppIcon";

export default function PageCommentsSection({wpPageId, comments}: { wpPageId: string, comments: IWPCommentsPayload[] }) {
    const [isLoading, setIsLoading] = useState(false);
    const [allCommentsLoad, setAllCommentsLoad] = useState(false);
    const [commentsDisplayed, setCommentsDisplayed] = useState<IWPCommentsPayload[]>(comments)

    async function fetchAllComments() {
        setIsLoading(true)
        setCommentsDisplayed(await getWPPageComments(wpPageId))
        setAllCommentsLoad(true)
        setIsLoading(false)
    }

    return <>
        {
            commentsDisplayed.map(({comment, responses}: IWPCommentsPayload, index: number) => (
                <div key={`comment-${index}`} className="flex flex-col space-y-4">
                    <Comment comment={comment} />
                    {responses?.map((response: IWPComment, responseIndex: number) => (
                        <Comment
                            key={`response-${index}-${responseIndex}`}
                            comment={response}
                        />
                    ))}
                </div>
            ))
        }

        {!allCommentsLoad && (
            <button
                className="flex w-52 mx-auto justify-center items-center gap-2 px-5 py-4 rounded-lg md:rounded font-bold text-white text-sm bg-orange-700
                            transition-all duration-300 whitespace-nowrap hover:shadow-outline hover:shadow-orange-500"
                onClick={() => fetchAllComments()}
            >
                {isLoading
                    ? <><AppIcon type={"solid"} iconClassName={"fa-spinner fa-spin"}/>Chargement </>
                    : 'Voir plus'
                }
            </button>
        )}
    </>
}


export function Comment({comment}: { comment: IWPComment }) {
    const getFormattedDate = (date: string) => {
        const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};
        const dateObj = new Date(date ?? '');
        // @ts-ignore
        return dateObj.toLocaleDateString('fr-FR', dateOptions);
    }

    return (
        <div
            className={`relative overflow-hidden flex flex-col w-[90%] p-4 rounded-md text-align-left gap-4
            ${comment.author !== 0 ? 'border-2 border-orange-600 ml-auto' : 'mr-auto'}`}
        >
            <span className="font-semibold">
                {comment.author !== 0 ? 'Expert Réassurez-moi' : comment.author_name}
                , le {getFormattedDate(comment.date)}
            </span>
            {comment.author !== 0 &&
                <span
                    className="absolute flex items-center justify-center bg-orange-600 h-10 w-48 text-sm font-semibold
                rotate-45 text-white p-2 -right-14 top-5"
                >
                    Notre expert
                </span>
            }
            <div
                dangerouslySetInnerHTML={{__html: comment.content.rendered}}
            />
        </div>
    )
}
