'use client'
import 'react-loading-skeleton/dist/skeleton.css';
import Image from "next/image";
import React, {useState, ReactNode} from "react";
import {IAppliedFilter, IColumnConfig, ITemplateConfig} from "@/app/models/auctor";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";
import AppTooltipWrapper from "@/app/components/atomic/molecules/AppTooltipWrapper";
import StarsRating from "@/app/components/atomic/StarsRating";
import dynamic from "next/dynamic";
const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'));
import ResultButton from "@/app/components/auctor/result/ResultButton";

interface IResultColumnProps {
    column: IColumnConfig,
    resultRow: any,
    appliedFilters: IAppliedFilter[],
    isPreview?: boolean,
    templateConfig: ITemplateConfig
    isWrappableOnMobile?: boolean,
    setMobileWrappedColumn?: any
}

interface IResultColumnContentProps {
    column: IColumnConfig,
    resultRow: any,
    templateConfig: ITemplateConfig
}

interface IResultColumnWrapperProps {
    column: IColumnConfig,
    isWrappableOnMobile: boolean | undefined,
    children: ReactNode
}

export enum DATA_COLUMN_TYPES {
    STRING = 'string',
    NUMBER = 'number',
    HTML = 'html',
    IMAGE_URL = 'image_url',
    EXPRESSION = 'expression',
    BUTTON = 'button',
}

export default function ResultColumn({
    column,
    resultRow,
    appliedFilters,
    isPreview,
    templateConfig,
    isWrappableOnMobile,
    setMobileWrappedColumn
}: IResultColumnProps) {
    const [isWrappedOnMobile, setIsWrapped] = useState(isWrappableOnMobile);
    const toggleWrappedOnMobile = () => {
        setIsWrapped(!isWrappedOnMobile);

        setMobileWrappedColumn(!isWrappedOnMobile ? null : column);
    };

    return (
        <>
            {isWrappableOnMobile && (
                <ResultColumnWrapButton
                    column={column}
                    isWrappedOnMobile={isWrappedOnMobile}
                    setIsWrapped={toggleWrappedOnMobile}
                />
            )}
            <ResultColumnWrapper
                column={column}
                isWrappableOnMobile={isWrappableOnMobile}
            >
                <ResultColumnContent
                    column={column}
                    resultRow={resultRow}
                    templateConfig={templateConfig}
                />
            </ResultColumnWrapper>
        </>
    );

    function ResultColumnWrapButton({column, isWrappedOnMobile, setIsWrapped}: {
        column: IColumnConfig,
        isWrappedOnMobile: boolean | undefined,
        setIsWrapped: any
    }) {
        return (
            <div
                className="flex justify-center items-center w-full gap-2 cursor-pointer bg-white p-3 sm:hidden font-medium"
                onClick={() => setIsWrapped(!isWrappedOnMobile)}
            >
                {column.wrapped_label ?? 'Afficher'}
                <span className={`fas fa-lg ${isWrappedOnMobile ? 'fa-caret-down' : 'fa-caret-up'}`} />
            </div>
        )
    }

    function ResultColumnWrapper({column, isWrappableOnMobile, children}: IResultColumnWrapperProps) {
        const classNames = `sm:flex flex-col items-center justify-center px-4 py-2 overflow-hidden text-sm gap-3 
        md:py-4 md:gap-4 md:col-span-${column.col_span} ${isWrappableOnMobile ? 'hidden' : 'flex'}`

        const styles = column.background_color ? {background: column.background_color} : {}

        return (
            <>
                {column.tooltip && (
                    <AppTooltipWrapper
                        id={column.id}
                        text={column.tooltip}
                        className={classNames}
                        style={styles}
                    >
                        {children}
                    </AppTooltipWrapper>
                )}

                {!column.tooltip && (
                    <div
                        key={column.id}
                        className={classNames}
                        style={styles}
                    >
                        {children}
                    </div>
                )}
            </>
        )
    }

    function ResultColumnContent({column, resultRow, templateConfig}: IResultColumnContentProps) {
        return (
            <>
                {column.top_additional_expression && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: evaluateExpressionWithFiltersAndRowData(column.top_additional_expression, resultRow, appliedFilters) ?? '',
                        }}
                    />
                )}
                {column.column_type === DATA_COLUMN_TYPES.IMAGE_URL && column.data_column_name !== null && (
                    <>
                        <div className="flex justify-around w-full relative">
                            <ResultLogo src={resultRow[column.data_column_name]} alt={column.label ? column.label : 'logo assureur'} />

                            {templateConfig.rating_column_name && (
                                <div className="md:hidden flex items-center">
                                    <StarsRating
                                        onlyOneStar
                                        displayRatingNotation
                                        displayMaxNotation
                                        notation={parseFloat(resultRow[templateConfig.rating_column_name])}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
                {templateConfig.rating_column_name && column.show_rating &&
                    Number.isFinite(parseFloat(resultRow[templateConfig.rating_column_name])) && (
                        <div className="whitespace-nowrap justify-start items-center w-full gap-2 hidden md:flex">
                            <span className="text-base text-blue-700 font-semibold">Notre note</span>
                            <div className="bg-blue-100 rounded-full py-1 px-1.5 text-blue-700">
                                <StarsRating
                                    displayRatingNotation
                                    displayMaxNotation
                                    notationOnTheLeft
                                    noteFontSize="text-base"
                                    notation={parseFloat(resultRow[templateConfig.rating_column_name])}
                                />
                            </div>
                        </div>
                    )}
                {column.column_type === DATA_COLUMN_TYPES.HTML && column.data_column_name !== null && (
                    <div
                        className="self-start text-[13px] font-light leading-normal"
                        dangerouslySetInnerHTML={{__html: resultRow[column.data_column_name] ?? ''}}
                    />
                )}
                {column.column_type === DATA_COLUMN_TYPES.EXPRESSION && column.expression && (
                    <div
                        className="w-full"
                        dangerouslySetInnerHTML={{
                            __html: evaluateExpressionWithFiltersAndRowData(column.expression, resultRow, appliedFilters) ?? '',
                        }}
                    />
                )}
                {column.column_type === DATA_COLUMN_TYPES.STRING && column.data_column_name !== null && (
                    <div className="text-[13px] font-light leading-normal">
                        {resultRow[column.data_column_name]}
                    </div>
                )}
                {column.column_type === DATA_COLUMN_TYPES.NUMBER && column.data_column_name !== null && (
                    <div>{resultRow[column.data_column_name]}</div>
                )}
                {column.column_type === DATA_COLUMN_TYPES.BUTTON
                    && templateConfig.price_expression !== null
                    && (
                        <div
                            className="hidden md:flex"
                            dangerouslySetInnerHTML={{
                                __html: evaluateExpressionWithFiltersAndRowData(templateConfig.price_expression, resultRow, appliedFilters) ?? '',
                            }}
                        />
                    )}
                {column.column_type === DATA_COLUMN_TYPES.BUTTON
                    && (
                        <ResultButton
                            column={column}
                            resultRow={resultRow}
                            appliedFilters={appliedFilters}
                        />
                    )}
                {column.bottom_additional_expression && (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: evaluateExpressionWithFiltersAndRowData(column.bottom_additional_expression, resultRow, appliedFilters) ?? '',
                        }}
                    />
                )}
            </>
        )
    }
}

const ResultLogo = React.memo(({src, alt}: { src: string, alt: string }) => {
    return (
        <div className="w-32 md:w-28 h-20 relative object-contain">
            <Image
                src={src}
                alt={alt}
                fill
                className="object-contain"
                sizes="(max-width: 768px) 40vw, (max-width: 1200px) 30vw, 10vw"
            />
        </div>
    );
}, (prevProps, nextProps) => prevProps.src === nextProps.src);
ResultLogo.displayName = 'ResultLogo';
