'use client'
import AppIcon from "@/app/components/atomic/AppIcon";
import Link from "next/link";

export default function FooterSocials() {
    const redirectLink = (link: any) => {
        window.open(link, '_blank');
    }

    return <>
        <div className="mt-4 hidden md:!flex gap-2">
            <span
                className="linkedin" onClick={() => redirectLink("https://www.linkedin.com/company/reassurez-moi")}
                rel="noreferrer" role="button" aria-label="Reassurez-moi Linkedin"
            >
                <AppIcon className={'mr-2'} iconClassName={'fa-linkedin'} type={'brands'} size={'2xl'} title='Reassurez-moi Linkedin' />
            </span>

            <span
                className="twitter" onClick={() => redirectLink("https://twitter.com/reassurezmoi")}
                rel="noreferrer" role="button" aria-label="Reassurez-moi Twitter"
            >
                <AppIcon className={'mr-2'} iconClassName={'fa-twitter'} type={'brands'} size={'2xl'} title='Reassurez-moi Twitter' />
            </span>

            <span
                className="instagram" onClick={() => redirectLink("https://www.instagram.com/reassurezmoi.fr/")}
                rel="noreferrer" role="button" aria-label="Reassurez-moi Instagram"
            >
                <AppIcon className={'mr-2'} iconClassName={'fa-instagram'} type={'brands'} size={'2xl'} title='Reassurez-moi Instagram' />
            </span>

            <span
                className="facebook" onClick={() => redirectLink("https://www.facebook.com/reassurezmoi/")}
                rel="noreferrer" role="button" aria-label="Reassurez-moi Facebook"
            >
                <AppIcon className={'mr-2'} iconClassName={'fa-facebook'} type={'brands'} size={'2xl'} title='Reassurez-moi Facebook' />
            </span>

            <span
                className="youtube" onClick={() => redirectLink("https://www.youtube.com/@ReassurezmoiFr1/")}
                rel="noreferrer" role="button" aria-label="Reassurez-moi Youtube"
            >
                <AppIcon className={'mr-2'} iconClassName={'fa-youtube'} type={'brands'} size={'2xl'} title='Reassurez-moi Youtube' />
            </span>

            <Link className="twitter" href="/contact" role="button" aria-label="Nous contacter">
                <AppIcon className={'mr-2'} iconClassName={'fa-envelope'} size={'2xl'} title='Reassurez-moi Nous contacter' />
            </Link>
        </div>
    </>
}
