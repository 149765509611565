import React from 'react';
import dynamic from 'next/dynamic';

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'));

const Tooltip = dynamic(() =>
    import('react-tooltip').then((mod) => mod.Tooltip)
);

interface AppTooltipWrapperProps {
    id: string | number;
    text: string;
    children: React.ReactNode;
    iconClassName?: string;
    className?: string;
    style?: React.CSSProperties;
    theme?: 'light' | 'dark' | 'warning' | 'error' | 'success' | 'info';
}

export default function AppTooltipWrapper({
    id,
    children,
    text,
    iconClassName,
    theme = 'dark',
    className = 'flex items-center justify-start gap-2',
    style
}: AppTooltipWrapperProps) {
    return (
        <>
            <div
                className={className}
                data-tooltip-content={text}
                data-tooltip-id={String(id)}
                style={style}
            >
                {children}
                {iconClassName &&
                    <AppIcon
                        iconClassName={iconClassName}
                        className="text-blue-700"
                        type="light"
                    />
                }
            </div>
            <Tooltip
                id={String(id)}
                variant={theme}
                className="z-50 max-w-[20rem] opacity-100 font-normal shadow p-4 rounded-lg text-gray-200"
            />
        </>
    );
};
