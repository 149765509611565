'use client'
import React, {useState, useCallback} from "react";
import {IAppliedFilter, IButtonConfig, IColumnConfig} from "@/app/models/auctor";
import {evaluateExpressionWithFiltersAndRowData} from "@/app/utils/expression-utils";
import AppButton from "@/app/components/atomic/molecules/AppButton";
import dynamic from "next/dynamic";
import {getWCTRedirectUrl, trackPartnerLinkClick, isPartnerLink} from "@/app/lib/wecantrack/clickout";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'), {
    ssr: false,
});

interface IResultButtonProps {
    column: IColumnConfig,
    resultRow: any,
    appliedFilters: IAppliedFilter[],
}

export default function ResultButton({
    column,
    resultRow,
    appliedFilters,
}: IResultButtonProps) {
    const [isButtonLoading, setButtonLoading] = useState(false);
    const getButtonLink = useCallback((columnConfig: IColumnConfig, resultRow: any) => {
        const buttonConfig = columnConfig.button_config as IButtonConfig
        if (buttonConfig && buttonConfig.has_conditional_url && buttonConfig.conditional_url_expression) {
            const expression = buttonConfig.conditional_url_expression

            const url = evaluateExpressionWithFiltersAndRowData(expression, resultRow, appliedFilters)

            if (url) {
                return url
            }
        }

        if (columnConfig.data_column_name === null) {
            return null
        }

        return resultRow[columnConfig.data_column_name]
    }, [column, resultRow, appliedFilters]);

    if (!column.button_config) {
        return null
    }

    const buttonClicked = async () => {
        if (!column.button_config) {
            return
        }

        const dataLayer = window.dataLayer || []
        setButtonLoading(true)

        if (column.button_config.gtm_event !== null) {
            dataLayer.push({event: column.button_config.gtm_event})
        }

        let buttonLink = getButtonLink(column, resultRow)

        if (isPartnerLink(buttonLink)) {
            trackPartnerLinkClick(buttonLink)
            const redirectUrl = await getWCTRedirectUrl(buttonLink)

            if (redirectUrl) {
                buttonLink = redirectUrl
            }
        }

        setButtonLoading(false)
        if (shouldOpenInNewTab(column.button_config, resultRow)) {
            window.open(buttonLink, '_blank')
        } else {
            window.location.href = buttonLink
        }
    };

    if (!column.button_config) {
        return null
    }

    const buttonLink = getButtonLink(column, resultRow);
    const shouldRenderButton = column.button_config && buttonLink !== null && buttonLink !== '';
    const shouldOpenInNewTab = (buttonConfig: IButtonConfig, resultRow: any) => {
        if (buttonConfig.target_self) {
            return false
        }

        if (buttonConfig.target_self_condition) {
            try {
                let expression = buttonConfig.target_self_condition
                if (!expression.includes('return')) {
                    expression = `return ${expression}`
                }

                const expressionFunction = new Function('data', expression)
                const expressionResult = expressionFunction(resultRow)

                return expressionResult !== true;
            } catch (error) {
                console.error(`Error evaluating condition for button target blank ${buttonConfig.id}:`, error)
                return true
            }
        }

        return true
    };

    const buttonComponent = (
        <AppButton
            theme="orangePrimary"
            className="font-semibold text-sm whitespace-nowrap px-5 py-4"
            isLink={!isPartnerLink(getButtonLink(column, resultRow))}
            isLinkTargetBlank={shouldOpenInNewTab(column.button_config as IButtonConfig, resultRow)}
            href={getButtonLink(column, resultRow)}
            onClick={() => buttonClicked()}
        >
            {isButtonLoading &&
                <>
                    <AppIcon iconClassName="fa-spinner-third" className="animate-spin mr-2" />
                    Accès en cours..
                </>
            }
            {!isButtonLoading &&
                <>
                    {column.button_config.icon &&
                        <AppIcon iconClassName={`${column.button_config.icon}`} />
                    }
                    {column.button_config.label}
                </>
            }
        </AppButton>
    );

    if (shouldRenderButton) {
        return buttonComponent;
    }

    const shouldDisplayFallback = column.button_config.fallback_text !== null && column.button_config.fallback_text !== undefined

    if (shouldDisplayFallback) {
        if (!column.button_config.fallback_text) {
            return null
        }

        const fallbackComponent = (
            <div
                dangerouslySetInnerHTML={{
                    __html: evaluateExpressionWithFiltersAndRowData(column.button_config.fallback_text, resultRow, appliedFilters) ?? '',
                }}
            />
        )

        return fallbackComponent;
    }
}
