import Skeleton from "react-loading-skeleton";
import {TBreadcrumb} from "@/app/models/wp";

interface IProps {
    breadcrumb: TBreadcrumb;
    isWhiteStyle?: boolean;
    isUnderlineStyle?: boolean;
}

export default function Breadcrumb({breadcrumb, isWhiteStyle = false, isUnderlineStyle = false}: IProps) {
    if (!breadcrumb || typeof breadcrumb !== "object") {
        return
    }

    return (
        <>
            <nav aria-label="breadcrumb" id="breadcrumb">
                <ul className="inline">
                {breadcrumb?.map((crumb: { url: string | undefined; text: string | undefined }, index: number) => (
                    <li key={index} className={`inline font-light text-xs lg:text-sm ${isWhiteStyle ? 'text-white' : 'text-blue-700 last:text-black'}`}>
                        {index !== breadcrumb.length - 1 ? (
                            <span>
                                <a
                                    className={`${isUnderlineStyle ? 'underline last:no-underline' : 'hover:underline last:hover:no-underline'}`}
                                    href={crumb.url}
                                >
                                    {crumb.text}
                                </a>

                                <span className="px-2">»</span>
                            </span>
                        ) : (
                            crumb.text
                        )}
                    </li>
                ))}
                </ul>
            </nav>
        </>
    )
}

export function BreadcrumbSkeleton() {
    return (
        <>
            <div className="flex gap-4">
                <Skeleton
                    containerClassName="h-5 flex opacity-50 w-3/12 md:w-1/12"
                />
                <Skeleton
                    containerClassName="h-5 flex opacity-50 w-4/12 md:w-1/12"
                />
                <Skeleton
                    containerClassName="h-5 flex opacity-50 w-3/12 md:w-1/12"
                />
            </div>
        </>
    )
}
