'use client'
import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'))

export default function FeebackRow() {
    const redirectOnReviewTypeform = () => {
        window.location.href = "https://reassurez-moi.typeform.com/to/ehBwxCFH"
    }

    const redirectOnTruspilotReview = () => {
        window.location.href = "https://fr.trustpilot.com/evaluate/reassurez-moi.fr"
    }

    return (
        <div className="flex p-4 my-6 items-center justify-center bg-blue-100 rounded gap-4">
            <span className="mr-2">Avez-vous aimé cet article ?</span>
            <span
                className="cursor-pointer"
                rel="nofollow"
                onClick={redirectOnReviewTypeform}
            >
                <AppIcon type="duotone" iconClassName={'fa-thumbs-down text-blue-700'} size={'xl'} />
            </span>
            <span
                className="cursor-pointer"
                rel="nofollow"
                onClick={redirectOnTruspilotReview}
            >
                <AppIcon type="duotone" iconClassName={'fa-thumbs-up text-blue-700'} size={'xl'} />
            </span>
        </div>
    )
}
