import dynamic from "next/dynamic";

const AppIcon = dynamic(() => import('@/app/components/atomic/AppIcon'));

interface Props {
    notation: number
    onlyFullStars?: boolean
    onlyOneStar?: boolean
    color?: 'yellow' | 'green' | 'blue'
    noteColorClass?: string
    notationOnTheLeft?: boolean
    displayRatingNotation?: boolean
    displayMaxNotation?: boolean
    starsFontSize?: string
    noteFontSize?: string
    gap?: string
}

export default function StarsRating({
    notation,
    onlyFullStars = false,
    onlyOneStar = false,
    color = 'yellow',
    displayRatingNotation = false,
    displayMaxNotation = false,
    notationOnTheLeft = false,
    starsFontSize = 'text-base',
    noteFontSize = 'text-base',
    noteColorClass = 'text-base',
    gap = 'gap-1.5',
}: Props) {
    const ratingOn5 = notation > 5 ? notation / 2 : notation

    const roundedStars = Math.round(ratingOn5)
    let fullStars = Math.floor(ratingOn5)
    let halfStars = ratingOn5 - fullStars > 0 ? 1 : 0
    let emptyStars = ratingOn5 - fullStars === 0 ? 5 - fullStars : 4 - fullStars
    let emptyStarsRound = 5 - roundedStars

    const colorClass =
        color === 'yellow'
            ? 'text-yellow-700'
            : color === 'green'
                ? 'text-green-500'
                : 'text-blue-500'

    if (onlyOneStar) {
        fullStars = 1
        halfStars = 0
        emptyStars = 0
        emptyStarsRound = 0
    }

    const notationFormatted = ratingOn5.toString().replace('.', ',')

    return (
        <div className={`flex items-center h-4 ${gap}`}>
            {displayRatingNotation && notationOnTheLeft &&
                <div className={`whitespace-nowrap font-semibold pt-0.5 mr-1 ${noteFontSize} ${noteColorClass}`}>
                    {notationFormatted}{displayMaxNotation && <span className='text-[0.6rem]'>&nbsp;/&nbsp;5</span>}
                </div>
            }

            {!onlyFullStars ? (
                <>
                    {Array.from({length: fullStars}, (_, i) => (
                        <AppIcon
                            key={i}
                            type="solid"
                            iconClassName={`fa-star ${colorClass} ${starsFontSize}`}
                        />
                    ))}

                    {Array.from({length: halfStars}, (_, i) => (
                        <AppIcon
                            key={i}
                            type="solid"
                            iconClassName={`fa-star-half-stroke ${colorClass} ${starsFontSize}`}
                        />
                    ))}

                    {Array.from({length: emptyStars}, (_, i) => (
                        <AppIcon
                            key={i}
                            type="regular"
                            iconClassName={`fa-regular fa-star ${colorClass} ${starsFontSize}`}
                        />
                    ))}
                </>
            ) : (
                <>
                    {Array.from({length: roundedStars}, (_, i) => (
                        <AppIcon
                            key={i}
                            type="solid"
                            iconClassName={`fa-star ${colorClass} ${starsFontSize}`}
                        />
                    ))}

                    {Array.from({length: emptyStarsRound}, (_, i) => (
                        <AppIcon
                            key={i}
                            type="regular"
                            iconClassName={`fa-regular fa-star ${colorClass} ${starsFontSize}`}
                        />
                    ))}
                </>
            )}

            {displayRatingNotation && !notationOnTheLeft &&
                <div className={`font-semibold pt-0.5 ${noteFontSize} ${noteColorClass}`}>
                    {notationFormatted}{displayMaxNotation && <span className='text-[0.6rem]'>&nbsp;/&nbsp;5</span>}
                </div>
            }
        </div>
    )
}
