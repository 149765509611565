import {
    ICommentsNumber,
    IPostsNumber,
    IWPCommentsPayload,
    IWPContentPayload,
    IWPIdBySlug,
    IWPLastPost,
    IWPPageMetaPayload,
    IYoastPageMetaPayload,
    TBreadcrumb
} from "@/app/models/wp";
import {notFound} from "next/navigation";
import {ISchema} from "@/app/models/schema";
import {IYoastSEOMeta} from "@/app/models/yoast";

export const BASE_API: string = `${process.env.NEXT_PUBLIC_RM_WORDPRESS_API}/api`
export const WP_API: string = `${process.env.NEXT_PUBLIC_RM_WORDPRESS_API}/guide/wp-json/rm-api`
export const YOAST_API: string = `${process.env.NEXT_PUBLIC_RM_WORDPRESS_API}/guide/wp-json/yoast/v1`
export const WP_PATH_PREFIX = '/guide'

export const ENDPOINTS = {
    GET_PAGE_CONTENT: `${BASE_API}/wp/page/content/by-id?id=%ID%`,
    GET_POST_CONTENT: `${BASE_API}/wp/post/content/by-id?id=%ID%`,
    GET_PAGE_META: `${BASE_API}/wp/page/meta/by-id?id=%ID%`,
    GET_PAGE_COMMENTS: `${BASE_API}/wp/page/comments/by-id?id=%ID%`,
    GET_COMMENTS_NUMBER: `${WP_API}/get-total-comments-number?pageId=%ID%`,
    POST_NEW_COMMENT: `${BASE_API}/wp/page/comment/for-id`,
    GET_BREADCRUMB_BY_ID: `${WP_API}/get-breadcrumb-by-id?pageId=%ID%`,
    GET_WP_BY_ID: `${WP_API}/get-page-by-id?pageId=%ID%`,
    GET_ID_BY_SLUG: `${WP_API}/get-page-id-by-slug?slug=%SLUG%`,
    GET_YOAST_META_BY_ID: `${WP_API}/get-meta-by-id?pageId=%ID%`,
    GET_YOAST_SCHEMA_BY_ID: `${WP_API}/get-schema-by-id?pageId=%ID%`,
    GET_YOAST_METAS: `${YOAST_API}/get_head?url=https://reassurez-moi.fr%SLUG%`,
    GET_ACTUS: `${WP_API}/get-actus?actualPage=%PAGE%&postsPerPage=%PER_PAGE%`,
    GET_TOTAL_PAGES_BY_CATEGORY: `${WP_API}/get-total-pages-by-category?category=%CATEGORY%`,
    GET_NEIGHBORS_POSTS: `${WP_API}/get-neighbors-posts?postId=%POST_ID%&postsNumber=%POSTS_NUMBER%`,
    GET_REDIRECTIONS: `${WP_API}/get-wp-redirections`,
    GET_WP_SLUGS: `${WP_API}/slugs`,
    GET_WP_SLUGS_FOR_SITEMAP: `${WP_API}/slugs-for-sitemap`,
    GET_FULL_LINK: `${WP_API}/prettylink?short_url=%SHORT_URL%`,
}

export async function getDataFromAPI(url: string, cache: boolean = true): Promise<any> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const response = await fetch(url,
        {
            cache: cachePolicy,
            headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }
        });

    return response.json();
}

export async function getFulLUrlFromPrettyLink(shortUrl: string): Promise<{ short_url: string, full_url: string }> {
    const url = ENDPOINTS.GET_FULL_LINK.replace('%SHORT_URL%', shortUrl)

    const response = await fetch(url, { headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' } })

    return response.json()
}

export async function getTotalPagesByCategory(category: string, cache: boolean = true): Promise<IPostsNumber> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_TOTAL_PAGES_BY_CATEGORY.replace('%CATEGORY%', String(category))
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});
    if (!response.ok) {
        return notFound()
    }
    return response.json();
}

export async function getWPIdBySlug(slug: string, cache: boolean = true): Promise<IWPIdBySlug> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_ID_BY_SLUG.replace('%SLUG%', slug)
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});
    if (!response.ok) {
        return notFound()
    }
    return response.json();
}

export async function getWPContent(id: string, cache: boolean = true): Promise<IWPContentPayload> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_WP_BY_ID.replace('%ID%', id)
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok) {
        return notFound()
    }
    return response.json();
}

export async function getWPPageMeta(id: string, cache: boolean = true): Promise<IWPPageMetaPayload> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_PAGE_META.replace('%ID%', id)
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getYoastPageMeta(slug: string, cache: boolean = true): Promise<IYoastPageMetaPayload> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_YOAST_METAS.replace('%SLUG%', slug)
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getWPPageComments(id: string, commentsNumber?: number, cache: boolean = true): Promise<IWPCommentsPayload[]> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    let url = ENDPOINTS.GET_PAGE_COMMENTS.replace('%ID%', id)
    if (commentsNumber) {
        url += `&commentsNumber=${commentsNumber}`
    }
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getCommentsNumber(id: string, cache: boolean = true): Promise<ICommentsNumber> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    let url = ENDPOINTS.GET_COMMENTS_NUMBER.replace('%ID%', id)
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    return response.json();
}

export async function getNeighborsPosts(wpPageId: string, postsNumber: string, cache: boolean = true): Promise<IWPLastPost[]> {
    const url = ENDPOINTS.GET_NEIGHBORS_POSTS.replace('%POST_ID%', wpPageId).replace('%POSTS_NUMBER%', postsNumber);
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getActus(actualPage: number, postsPerPage: number, cache: boolean = true): Promise<IWPLastPost[]> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_ACTUS.replace('%PAGE%', String(actualPage)).replace('%PER_PAGE%', String(postsPerPage))
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getNewsSchemaBySlug(slug: string): Promise<any> {
    const url = `${BASE_API}/wp/post/news-schema/by-slug?slug=` + slug
    const response = await fetch(url, { headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' } })

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getBreadcrumbByID(id: string | number, cache: boolean = true): Promise<TBreadcrumb> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'

    id = String(id)

    const response = await fetch(ENDPOINTS.GET_BREADCRUMB_BY_ID.replace('%ID%', id), {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getYoastMetaByID(id: string, cache: boolean = true): Promise<IYoastSEOMeta> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'
    const url = ENDPOINTS.GET_YOAST_META_BY_ID.replace('%ID%', id)
    const response = await fetch(url, {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}

export async function getYoastSchemaByID(id: string, cache: boolean = true): Promise<ISchema> {
    const cachePolicy = cache ? 'force-cache' : 'no-store'

    const response = await fetch(ENDPOINTS.GET_YOAST_SCHEMA_BY_ID.replace('%ID%', id), {cache: cachePolicy, headers: { 'HTTP_X_GUIDE_PROXY_KEY': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe', 'X-Guide-Proxy-Key': 'FUbXPtxQYy9sWp97OGMammOnjiDIfNBe' }});

    if (!response.ok && process.env.APP_ENV === 'prod') {
        return notFound()
    }

    return response.json();
}
