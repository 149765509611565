'use client'
import React, {ReactNode, RefObject, useEffect, useState} from "react";
import '@/app/styles/wordpress/WPContent.scss';
import '@/app/styles/wordpress/WPSidebar.scss';
import {IHeadingData} from "@/app/models/wp";
import useIntersectionObserver from "@/app/hooks/useIntersectionObserver";
import MediaShareLinks from "@/app/components/atomic/molecules/MediaShareLinks";
import WPTOC, {WPTOCSkeleton} from "@/app/components/auctor/page/WPTOC";

interface IShareNavigator {
    title: string
    text: string
    url: string
}

interface IPageSidebar {
    children?: ReactNode | null
    hasAboutBlock?: boolean
    hasTOC?: boolean
    showSidebarAdvertblockContainer?: boolean,
    shareNavigator?: IShareNavigator,
    intersectionAnchorRef: RefObject<any>,
    TOC?: IHeadingData[],
    defaultAdvertBlock?: null | string,
}

export default function PageSidebar(
    {children, hasAboutBlock, hasTOC, shareNavigator, intersectionAnchorRef, showSidebarAdvertblockContainer = false, TOC, defaultAdvertBlock}: IPageSidebar) {
    const [currentUrl, setCurrentUrl] = useState<string>('');

    const {isIntersecting, IntersectionAnchor} = useIntersectionObserver(
        intersectionAnchorRef,
        {
            rootMargin: '0px 0px -100% 0px',
        },
    )

    useEffect(() => {
        setCurrentUrl(window?.location.href);
    }, []);

    return <>
        <div className={"wp-sidebar"}>
            <div className={`lg:sticky md:top-28 lg:flex flex-col w-full lg:w-[300px] gap-12 ${isIntersecting ? 'flex' : 'hidden'}`}>
                {hasTOC && TOC && TOC.length !== 0 && (
                    <WPTOC TOC={TOC} />
                )}

                {children}

                {defaultAdvertBlock &&
                    <div
                        id="default-advertblock"
                        className="fixed bottom-0 left-0 w-full lg:w-auto lg:relative gap-2 flex flex-col"
                        dangerouslySetInnerHTML={{__html: defaultAdvertBlock}}
                    />
                }

                {!defaultAdvertBlock &&
                    <div
                        id="fix-sidebar-content"
                        className="fixed bottom-0 left-0 w-full lg:w-auto lg:relative gap-2 flex flex-col"
                    />
                }

                {showSidebarAdvertblockContainer &&
                    <div id="sidebar-advertblock-container" className="mt-2 hidden lg:flex lg:flex-col" />
                }

                {hasAboutBlock && (
                    <div className={'hidden lg:flex lg:flex-col lg:gap-4'}>
                        <div className={'flex flex-col p-5 gap-4 rounded-xl bg-blue-200'}>
                            <div
                                className={'text-lg font-semibold'}
                            >
                                Qui sommes-nous ?
                            </div>

                            <div
                                className={'text-blue-700 text-sm'}
                            >
                                Réassurez-moi.fr est un courtier en ligne spécialisé en Assurance Emprunteur et Mutuelle Santé. Gratuit et 100 % transparent,
                                notre mission est de vous accompagner dans le choix de vos assurances. Nous agissons aussi comme comparateur pour le prêt
                                immobilier, l’assurance voyage, la dépendance ou encore les animaux, afin de vous proposer les meilleures offres adaptées
                                à vos besoins.
                            </div>
                        </div>

                        <div className={'text-lg font-semibold'}>Partagez cet article</div>

                        <MediaShareLinks
                            sharedMedia={'facebook, x, linkedin, whatsapp'}
                            shareNavigator={shareNavigator}
                            urlToShare={currentUrl}
                            hasShareButton
                        />
                    </div>
                )}
            </div>
        </div>
    </>
}

export function PageSidebarSkeleton() {
    return (
        <>
            <WPTOCSkeleton />
        </>
    )
}
