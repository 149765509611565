import {getFulLUrlFromPrettyLink} from "@/app/endpoints/wpEndpoints";

export const getWCTRedirectUrl = async (cloakedUrl: string) => {
    // we only need the path and most of the time we have the full URL
    let path = new URL(cloakedUrl).pathname;

    if (path.startsWith('/guide')) {
        path = path.replace('/guide', '');
    }

    // remove the starting slash
    if (path.startsWith('/')) {
        path = path.slice(1);
    }

    const fullUrl = await fetchFullUrlFromCloakedUrl(path);
    const url = new URL(fullUrl);
    url.searchParams.append('clickid', '{clickid}');

    const referer = document.location.href;
    const gaCookie = document.cookie.match(/_ga=([^;]*)/)?.[1] || null;
    const wctrckCookie = document.cookie.match(/_wctrck=([^;]*)/)?.[1] || null;

    const response = await fetch('/api/wecantrack/clickout', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            cloaked_url: url.toString(),
            referer,
            ga_cookie: gaCookie,
            wctrck_cookie: wctrckCookie,
        }),
    });

    if (!response.ok) {
        return null;
    }

    const {redirect_url} = await response.json();

    return redirect_url;
}

const fetchFullUrlFromCloakedUrl = async (cloakedUrl: string): Promise<string> => {
    const response = getFulLUrlFromPrettyLink(cloakedUrl);

    const {full_url} = await response;
    return full_url;
};

export const isPartnerLink = (url: string) => {
    return url.includes('/guide/partenaire');
}

export const trackPartnerLinkClick = (url: string) => {
    const dataLayer = window.dataLayer || []
    dataLayer.push({event: 'gtm_partner_link_clic', partner_url: url})
}

export const clickoutAndRedirect = async (cloakedUrl: string) => {
    const redirect_url = await getWCTRedirectUrl(cloakedUrl);
    if (redirect_url) {
        window.location.href = redirect_url; // Redirect the user
    }
}
