'use client'
import React, {MouseEventHandler, useCallback} from 'react';
import Link from "next/link";

export interface AppButtonProps {
    theme?: keyof typeof THEMES;
    size?: keyof typeof SIZES;
    radius?: keyof typeof RADIUS;
    isLink?: boolean;
    isRouterLink?: boolean;
    isLinkTargetBlank?: boolean;
    onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement | MouseEvent>;
    href?: string;
    prefetch?: boolean
    className?: string;
    children?: any;
    isFullWidth?: boolean;
    isHoverAnimationDisable?: boolean;
    hoveredChildren?: any;
    disabled?: boolean;
}

const THEMES = {
    orangePrimary: 'bg-orange-700 text-white hover:outline hover:outline-4 outline-orange-500',
    orangeSecondary: 'bg-orange-300 text-orange-700 hover:outline hover:outline-4 outline-orange-500',
    bluePrimary: 'bg-blue-500 text-white hover:outline hover:outline-4 outline-blue-300 [&.pointer-events-none]:bg-[#0e69c980]',
    blueSecondary: 'bg-blue-100 text-blue-700 hover:outline hover:outline-4 outline-blue-300',
    greenPrimary: 'bg-green-700 text-white enabled:hover:outline enabled:hover:outline-4 outline-green-200',
    greenSecondary: 'bg-green-300 text-green-700 hover:outline hover:outline-4 outline-green-500',
    whitePrimaryArrow: 'bg-white text-blue-700 hover:outline hover:outline-2 outline-blue-700 justify-between',
    step: 'bg-white text-blue-700 rounded-md border border-blue-200',
    stepBlue: 'bg-blue-200 text-blue-700 rounded border border-blue-200 hover:border-blue-300 px-[10px] py-[15px] text-rm-12 disabled:opacity-70 [&.pointer-events-none]:opacity-70',
} as const;

const THEMES_STATE: { [key: string]: any } = {
    step: {
        disabled: 'opacity-70',
    },
} as const;

const SIZES = {
    small: 'px-2 py-1 text-sm',
    medium: 'px-4 py-2 text-base',
    cta: 'p-4 text-base font-semibold',
    large: 'px-6 py-3 text-lg font-semibold',
    block: 'w-full px-6 py-3 text-lg font-semibold',
} as const;

const RADIUS = {
    small: 'rounded-sm',
    normal: 'rounded',
    medium: 'rounded-md',
    large: 'rounded-lg',
    full: 'rounded-full',
} as const;

const DEFAULT_THEME = 'orangePrimary'

const AppButton = ({
    onClick,
    href,
    prefetch = false,
    theme = DEFAULT_THEME,
    isLink = false,
    isLinkTargetBlank = false,
    size = 'medium',
    radius = 'normal',
    className,
    isFullWidth = false,
    children,
    isHoverAnimationDisable = false,
    hoveredChildren = null,
    disabled = false,
}: AppButtonProps) => {
    const baseStyle = 'transition-all duration-100 flex justify-center items-center gap-2 focus:outline-none hover:gap-4';
    const themeStyle = THEMES[theme] || THEMES[DEFAULT_THEME];
    const radiusStyle = RADIUS[radius] || RADIUS.large;
    const stateStyle = THEMES_STATE[theme]?.disabled || ''
    const sizeStyle = SIZES[size] || SIZES.medium;
    const fullWidthStyle = isFullWidth ? 'w-full text-center' : '';
    const hover = isHoverAnimationDisable ? '' : 'transition-all duration-100';
    const buttonStyle = `${baseStyle} ${sizeStyle} ${themeStyle} ${radiusStyle} ${fullWidthStyle} ${hover} ${stateStyle}`;

    if (isLink && href) {
        return (
            <Link
                href={href}
                className={`${buttonStyle} ${className} ${disabled ? 'pointer-events-none' : ''}`}
                onClick={onClick}
                target={isLinkTargetBlank ? '_blank' : '_self'}
                prefetch={prefetch}
            >
                <ButtonContent hoveredChildren={hoveredChildren}>
                    {children}
                </ButtonContent>
            </Link>
        );
    }

    return (
        <button
            onClick={onClick}
            className={`${buttonStyle} ${className} group`}
            disabled={disabled}
        >
            <ButtonContent hoveredChildren={hoveredChildren}>
               {children}
            </ButtonContent>
        </button>
    );
}

const ButtonContent = ({children, hoveredChildren = null}: { children: any, hoveredChildren: any }) => {
    const getHoveredChildren = useCallback(() => {
        return hoveredChildren !== null ? hoveredChildren : children
    }, [])

    return (
        <>
            <span className={"group-hover:hidden"}>
                {children}
            </span>
            <span className={"hidden group-hover:block"}>
                {getHoveredChildren()}
            </span>
        </>
    )
}

export default React.memo(AppButton)
