'use client'

import React, { useEffect, useRef } from "react"
import { faqSection, initResponsiveTables } from "@/app/utils/wp-utils"
import { setupSidebarIntendedItemsOnScroll } from "@/app/utils/advertizing-block-utils"
import PageSidebar from "@/app/components/auctor/page/PageSidebar"
import { setupTOCReadFlow } from "@/app/utils/toc"
import { IWPContentPayload } from "@/app/models/wp"

export default function WPPageContent({
    pageContent,
    showInnerTitle,
    title,
    children,
}: {
    showInnerTitle: boolean
    title?: string
    pageContent: IWPContentPayload
    children?: any
}) {
    const isInit = useRef(false)
    const hasSidebar = pageContent.template.hasSidebar
    const sidebarRight = pageContent.template.sidebarRight

    useEffect(() => {
        if (!isInit.current) {
            // Delay event attachment to ensure DOM is fully updated after server-side rendering
            // This allows time for client-side hydration to complete, preventing loss of event listeners
            setTimeout(() => {
                initWPPageScripts(hasSidebar)
            }, 100)
            isInit.current = true
        }
    }, [hasSidebar, pageContent.content])

    const intersectionAnchorRef = React.createRef<HTMLDivElement>()

    return (
        <div
            ref={intersectionAnchorRef}
            className={`flex flex-col lg:flex-row pt-5 lg:pt-10 lg:gap-16 mx-4 w-full lg:max-w-screen-lg+
        ${hasSidebar ? ' justify-center md:justify-between' : ' justify-center'}`}
        >
            {hasSidebar && !sidebarRight && (
                <PageSidebar
                    hasTOC
                    intersectionAnchorRef={intersectionAnchorRef}
                    TOC={pageContent.headings}
                    defaultAdvertBlock={pageContent.defaultAdvertBlock}
                />
            )}

            <div className={`bg-white w-full${hasSidebar ? ' lg:max-w-[900px]' : ''}`}>
                {children}

                {showInnerTitle && title && (
                    <div className={"rm-page-content"}>
                        <h2 dangerouslySetInnerHTML={{ __html: title }} />
                    </div>
                )}

                <article
                    id="content"
                    suppressHydrationWarning
                    className={'post-content'}
                    dangerouslySetInnerHTML={{ __html: pageContent.content }}
                />
            </div>

            {hasSidebar && sidebarRight && (
                <PageSidebar
                    hasTOC
                    intersectionAnchorRef={intersectionAnchorRef}
                    defaultAdvertBlock={pageContent.defaultAdvertBlock}
                />
            )}
        </div>
    )
}

function initWPPageScripts(hasSidebar: boolean) {
    setupTOCReadFlow()
    faqSection()
    if (hasSidebar) {
        setupSidebarIntendedItemsOnScroll()
    }
    const isDeviceLowerThanMD = window.matchMedia('(max-width: 992px)').matches
    if (isDeviceLowerThanMD) {
        initResponsiveTables()
    }
}
